@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';


/* FlexForm ----------------------- */

.FlexForm :global(.Fieldset) {
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: auto;
  min-width: 0;
  padding-bottom: 0rem;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
}


/* FormRow ----------------------- */

:global(.FormRow) {
  flex: 1 0 100%;
  margin-bottom: 1rem;
  padding: 0 0.75rem;
  position: relative;

  :global(.Button) {
    margin-bottom: 0;
  }
}


/* FormLabel ----------------------- */

:global(.FormLabel) {
  margin-bottom: 0.25rem;

  abbr[title=required] {
    text-decoration: none;
    color: $orange;
  }

  @mixin above $small {
    font-size: 1rem;
  }
}

:global(.FormLabel--required) {
  &:after {
    content: " *";
    color: $orange;
  }
}


/* TextInput ----------------------- */

:global(.TextInput) {
  @mixin rounded-input;
  height: 3rem;
  width: 100%;
  border-color: $mediumDarkGrey;
}


/* TextArea ----------------------- */

:global(.TextArea) {
  @mixin rounded-input;
  width: 100%;
  line-height: 1.6;
  min-height: 5rem;
  border-color: $mediumDarkGrey;
}

/* Checkbox ----------------------- */

:global(.Checkbox) {
  font-size: 0.875rem;

  input[type=checkbox] {
    display: inline-block;
    width: auto;
    margin-right: 0.5rem;
  }
}

/* RadioGroup ----------------------- */

:global(.RadioGroup) {
  label {
    display: inline-block;
    margin-right: 1rem;
    font-size: 0.875rem;

    span {
      display: inline-block;
      margin-left: 0.4rem;
    }
  }

  :global(.RadioGroup-custom) {
    input {
      margin-right: 0.25rem;
    }
  }
}


/* ErrorExplanation ----------------------- */

:global(.ErrorExplanation),
:global(.input) :global(.hint),
:global(.input) :global(.error),
:global(.FormRow) :global(.hint) {
  margin-top: 0.4rem;
  font-size: 0.8rem;
  line-height: 1.3;
  display: block;

  @mixin above $small {
    font-size: 0.8rem;
  }
}

:global(.hint) {
  display: inline-block;
  margin-right: 0.5rem;
  line-height: 1.2;
}

:global(.ErrorExplanation),
:global(.input) :global(.error) {
  font-weight: 500;
  color: $red;
}