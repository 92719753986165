@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.Register, .CateringRegister, .Login {

  :global(.Box):global(.Box--white) {
    padding: 1.5em;
    border: 1px solid $grey;
  }

  h1 {
    font-weight: 500;
    font-size: 30px;
    letter-spacing: 0;
  }

  label {
    font-weight: 500;
  }

  .Button__facebook,
  .Button__google {
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
    }
  }

  .Button__google {
    border: 1px solid #747775;
    color: #1F1F1F;

    &:hover {
      background-color: white;
      border: 1px solid #747775;
      color: #1F1F1F;
    }

    img {
      width: 17px;
      padding-bottom: 4px;
    }
  }

  .Button__facebook {
    color: white;
    background-color: #1877F2;
    border: none;

    &:hover {
      color: white;
      background-color: #1877F2;
    }

    img {
      width: 20px;
      padding-bottom: 5px;
    }
  }

  .Button__apple {
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    background-color: black;

    &:hover {
      background-color: black;
    }
  }
}

.FormMessages {
  font-weight: 600;
  position: relative;
  background: $blue;
  flex: 1 1 auto;
  color: white;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-left: 4rem;
  vertical-align: middle;

  :global(.icon) {
    opacity: 0.3;
    display: block;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    margin-top: 0.1em;
    font-size: 1.5rem;
    transform: translateY(-50%);
  }

  h4 {
    width: 100%;
    color: white
  }

  a {
    display: inline-block;
    color: white;
    border-bottom: 2px solid rgba(255,255,255,0.25);
  }
}

.FormMessages + .FormMessages {
  margin-top: -1rem;
}

.FormMessages__error {
  background: $blue;
}

.OrRule {
  position: relative;
  overflow: visible;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: 'OR';
    transform: translateX(-50%) translateY(-50%);
    font-size: 0.85rem;
    background: white;
    padding: 0.5rem;
  }
}